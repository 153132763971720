import { useLocation } from "react-router-dom";
import type { PageTitleProps } from "./types";
import styles from "./styles.module.scss";

const PageTitle = ({ title }: PageTitleProps) => {
  const { state } = useLocation();

  if (title || state?.title) {
    return <h1 className={styles.title}>{title || state.title}</h1>;
  }

  return null;
};

export default PageTitle;
